.popup {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(0,0,0,0.4);

    display: flex;
    justify-content: center;
    align-self: center;
    z-index: 999;
}

.popup-inner {
    position: relative;
    padding: 32px;
    width: 90%;
    height: 100%;
    max-width: 640px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-self: center;
}

.popup-inner>.close-btn {
    position: absolute;
    top: 116px;
    right: -116px;
}

.popup-inner>img {
    transform: scale(0.8);
}

@media screen and (max-width: 768px) {
    .popup-inner>img {
        transform: scale(0.3);
        padding: 2rem 0;
    }
    .popup-inner>.close-btn {
        top: 19rem;
        right: 11rem;
    }
}
.Join {
    display: flex;
    gap: 15rem;
    padding: 0 2rem;
}

.left-j {
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.left-j>hr {
    position: absolute;
    width: 60.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
}
.left-j>div {
    display: flex;
    gap: 1rem;
}

.right-j {
    display: flex;
    justify-content: center;
    align-items: center;
}

.email-container {
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
    border-radius: 2rem;
}
.email-container>input {
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
}
::placeholder {
    color: var(--lightgray);
}


@media screen and (max-width: 768px) {
    .Join {
        flex-direction: column;
        gap: 1rem;
    }
    .left-j {
        font-size: x-large;
        flex-direction: column;
        line-height: 2.5rem;
    }
    .right-j {
        padding: 2rem;
    }




    .left-j>hr {
        width: 100%;
    }
    .email-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2rem;
    }
    .right-j {
        padding: 2rem 0;
    }
}